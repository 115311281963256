import Vue from "vue"
import Vuex from "vuex"
import locale from "@/store/modules/locale"
import app from "@/store/modules/app"
import router from "../router"
import secondaryNavs from "./modules/secondaryNavs"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    locale,
    secondaryNavs,
  },
  state: {
    inited: false,
    $alert: [],
  },
  actions: {
    async init(ctx) {
      // if (!ctx.getters.isMainLayout || ctx.getters.isInited) return
      console.log("ctx: loaded")
      ctx.dispatch("app/init")
      ctx.dispatch("locale/init")
      ctx.commit("setInited")
    },
    setAlert(ctx, params) {
      ctx.commit("mutateAlert", params)
    },
  },
  mutations: {
    setInited(state) {
      setTimeout(() => {
        state.inited = true
        console.log("ctx: initialized")
      }, 2000)
    },
    mutateAlert(state, params) {
      if (!params || typeof params !== "object") {
        state.$alert = []
        return
      }
      if (typeof params === "object") {
        params.show = true
        params.date = new Date()
        switch (params.type) {
          case "success":
            params.title = "success"
            params.icon = "mdi-check-circle-outline"
            break
          case "warning":
            params.title = "warning"
            params.icon = "mdi-alert-outline"
            break
          case "error":
            params.title = "error"
            params.icon = "mdi-close-circle-outline"
            break
          case "info":
            params.title = "info"
            params.icon = "mdi-information-outline"
            break
        }
      }
      state.$alert.push(params)
    },
    removeAlert(state, index) {
      state.$alert.splice(index, 1)
    },
  },
  getters: {
    isInited: (state) => state.inited,
    isMainLayout: () => {
      return router.history.current.meta.requiredAuth
        ? router.history.current.meta.requiredAuth
        : router.history.pending.meta.requiredAuth
    },
    getAlert: (state) => state.$alert,
  },
})
